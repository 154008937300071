<template>
  <section>
    <b-skeleton v-if="loading" ></b-skeleton>
    <span v-if="!loading">
        <span
          v-for="(participant, index) in firstParticipants"
          :key="participantKey(index)"><span v-if="index != 0">, </span>
          <img class="icon mr-1" v-if="participant.user_uuid" src="/img/message_type/message.png" /> <MessageStatus v-if="!draft" :status="participant.status"></MessageStatus>&nbsp;{{getName(participant)}}
          </span>
        <span v-if="totalCount >= 3"
          >, .. (+{{ totalNotInFirstCount }})</span
        >
       </span>
  </section>
  </template>
  <script>
  import MessageStatus from "./MessageStatus";
  export default {
    components: { MessageStatus },
    props: ["message_uuid", "external", "participants","external_system_participant", "user_uuid","draft"],
    data() {
      return {
        count: 0,
        loading: false,
        visible: false
      };
    },
    methods: {
      getName(participant){
        if(participant.email != "")
        {
          return participant.email;
        }
        if(participant.name != "")
        {
          return participant.name;
        }
        return participant.identifier;
      },
      participantKey(index)
      {
        return "participant_recipient_" + index;
      },
      checkFirst(index) {
        return index == 0 ? true : false;
      },
      getStatus(item) {
        if(item.email == ""){
          return 0;
        }
        if (item.response_message_uuid != "") {
          return 3;
        }
        if (item.recalled == 1) {
          return 2;
        }
        if (item.fetched != undefined) {
          if (item.fetched == 1) {
            return 1;
          }
        } else {
          if (item.viewed == 1) {
            return 1;
          }
        }
        if (item.sent == 0) {
          return 10;
        }
        return 0;
      },
      getStatusTitle(item) {
        if(item.email == ""){
          return "";
        }
        let status = this.getStatus(item);
        if (status == 3) {
          return this.$t("RESPONSED");
        }
        if (status == 2) {
          return this.$t("RECALLED");
        }
        if (status == 1) {
          return this.$t("VIEWED");
        }
        if (status == 10) {
          return this.$t("NOT_SENT");
        }
        return this.$t("NOT_VIEWED");
      },
      notInFirst(email) {
        let test = this.firstParticipants.filter(function(item) {
          return item.email == email;
        });
        if (test.length == 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      showButtonCaption() {
        if (this.visible) {
          return this.$t("HIDE");
        } else {
          return this.$t("SHOW_ALL");
        }
      },
      participantsWithoutFirst() {
        let self = this;
        return this.participants.filter(function(item) {
          if (self.notInFirst(item.email)) {
            return item;
          }
        });
      },
      externalWithoutFirst() {
        let self = this;
        return this.external.filter(function(item) {
          if (self.notInFirst(item.email)) {
            return item;
          }
        });
      },
      externalSystemWithoutFirst() {
        let self = this;
        if(this.external_system_participant != undefined){
          return this.external_system_participant.filter(function(item) {
            if (self.notInFirst(item.email)) {
              return item;
            }
          });
        }else{
          return [];
        }
      },
      firstParticipants() {
        let participants = this.participants.slice(0, 2);
        let external = this.external.slice(0, 2);
        if(this.external_system_participant != undefined){
          let externalSystemParticipant = this.external_system_participant.slice(0, 2);
          let recipients1 = externalSystemParticipant.concat(external);
          let recipients = participants.concat(recipients1);
          return recipients.slice(0, 2);
        }else{
          let recipients = participants.concat(external);
          return recipients.slice(0, 2);
        }      
      },
      totalCount() {
        if(this.external_system_participant != undefined){
          return this.participants.length + this.external.length + this.external_system_participant.length;
        }else{
          return this.participants.length + this.external.length;
        }
      },
      totalNotInFirstCount() {
       
        if(this.external_system_participant != undefined){
          return this.participantsWithoutFirst.length + this.externalWithoutFirst.length + this.externalSystemWithoutFirst.length;
        }else{
          return this.participantsWithoutFirst.length + this.externalWithoutFirst.length;
        }
      },
    },
    mounted() {
    },
  };
  </script>
  <style></style>  